import React, {
  useEffect,
  useState
} from "react"

import { Layout2 } from "../components/layout2"
import SEO from "../components/seo"
import { fetchClients } from "../utils/projects"
import { SendUsMessage } from "./"

export default function ClientsPage() {
  const [clients, setClients] = useState([])
  useEffect(() => {
    fetchClients().then(data => {
      setClients(data.map(d => d.fields))
    })
  }, [])
  return (
    <Layout2>
      <SEO title="Our Clients" />
      <div id="hero" className="relative client-hero bg-right bg-cover">
        <h1 className="py-24 text-5xl font-semibold text-white text-center">
          Our Clients
        </h1>
      </div>
      <div className="container mx-auto p-6 md:py-20 md:px-20">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-8 md:col-gap-6 md:row-gap-20 ">
          {clients.map(cl => (
            <div className="flex justify-center transition-transform duration-500  transform hover:scale-125">
              <img
                className="h-16"
                src={cl.Attachments?.[0]?.url}
                alt={cl.Name}
              />
            </div>
          ))}
        </div>
      </div>
      <SendUsMessage />
    </Layout2>
  )
}
